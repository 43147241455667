import axios from "axios";
import { toast } from "react-toastify";
import HelperService from "./HelperService";

//Development
// const LOGIN_BASE_URL = "https://staging-accounts.webmaxy.co";
// const BASE_URL = "https://staging-performanceapi.webmaxy.co/api/";


// //LIVE
const LOGIN_BASE_URL = "https://accounts.webmaxy.co";
const BASE_URL = "https://promarketerapi.webmaxy.co/api/";

export const GOOGLE_MAP_API_KEY = "AIzaSyAbGxCGRyI51IgjLi3sebel2fhLiMJ5Ygc";

interface PropData {
  action: string;
  body?: any;
  isFormData?: boolean;
  isShowError?: boolean;
  id?: string;
  _file?: File;
  key?: string;
  signal?: any
}

const WebService = {
  postAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .post(
          `${BASE_URL}${props.action}`,
          props.isFormData ? bodyFormData : props.body,
          {
            headers: headers,
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },
  uploadAPI: function (props: PropData) {
    let formData = new FormData();
    if (props._file && props.key) {
      formData.append(props.key, props._file);
    }
    return new Promise((resolve, reject) => {
      for (let key in props.body) {
        formData.append(key, props.body[key]);
      }
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .post(`${BASE_URL}${props.action}`, formData, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },
  putAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      var bodyFormData = new URLSearchParams();
      for (let key in props.body) {
        bodyFormData.append(key, props.body[key]);
      }
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        xapikey: localStorage.getItem("p_user_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .put(`${BASE_URL}${props.action}`, props.body, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }
          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  getAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      var ad: any = {
        headers: headers,
      };
      if (props.signal) {
        ad = {
          headers: headers,
          signal: props.signal
        }
      }
      axios
        .get(`${BASE_URL}${props.action}`, ad)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  deleteAPI: function (props: PropData) {
    return new Promise((resolve, reject) => {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
        appid: localStorage.getItem("app_id"),
        'x-tenant-id': localStorage.getItem("t_id"),
      };
      axios
        .delete(`${BASE_URL}${props.action}`, {
          headers: headers,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error && error.response && error.response.status === 401) {
            this.logout();
          }

          props.isShowError ? reject(this.errorHandler(error)) : reject(error);
        });
    });
  },

  errorHandler: function (error: any) {
    if (error?.response) {
      error = error.response;
    }
    var errorMessage;
    if (!error || !error.status) {
      errorMessage = "Server Not Responding";
    } else if (error.status === 401) {
      errorMessage = "Unauthorized";
      this.logout();
    } else if (error.status === 500) {
      errorMessage =
        (error &&
          error.data &&
          error.data.ErrorDetails &&
          error.data.ErrorDetails.message) ||
        "An unkown exception has occur. Please Contact administrator";
    } else if (error.status === 503) {
      return errorMessage;
    } else {
      errorMessage = error.data.message;
    }
    toast.error(errorMessage);
    return errorMessage;
  },

  addLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = true;
        var loader = document.createElement("span");
        loader.className = "loader";
        button.prepend(loader);
      }
    }
  },

  removeLoader(id: any) {
    if (id) {
      var button = document.getElementById(id) as HTMLButtonElement | null;
      if (button != null) {
        button.disabled = false;
        button.removeChild(button.childNodes[0]);
      }
    }
  },
  getLoginUrl() {
    return LOGIN_BASE_URL;
  },
  getBaseURL() {
    return BASE_URL;
  },

  logout() {
    let platform = HelperService.getAppType() !== undefined ? HelperService.getAppType() : "egrowth";
    console.log(platform);
    window.location.href = LOGIN_BASE_URL + "/promarketer-ai/logout";
  }
};

export default WebService;
