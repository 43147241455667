import WebService from "../Services/WebService";
export type ProtectedRouteProps = {
  authenticationPath: string;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  authenticationPath,
  outlet,
}: ProtectedRouteProps) {
  if (localStorage.getItem("access_token") != null) {
    return outlet;
  } else {
    let platform =  localStorage.getItem("platform") !== undefined
        ? localStorage.getItem("platform")
        : "egrowth";
    localStorage.clear();
    console.log(platform);
    window.location.href =
      WebService.getLoginUrl() + "/promarketer-ai/logout";
    return outlet;
  }
}
